<template>
  <div>
    <el-form ref="form" :model="formData" label-width="80px" :rules="rules">
      <el-form-item label="学年名称" prop="semester_name">
        <el-input clearable v-model="formData.semester_name"></el-input>
      </el-form-item>
      <el-form-item label="学年" prop="year">
        <el-date-picker
            clearable
            style="width: 100%"
            v-model="formData.year"
            type="year"
            format="yyyy"
            value-format="yyyy"
            placeholder="选择年">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="学期" prop="type">
        <el-select clearable class="w-full" v-model="formData.type">
          <el-option label="第一学期" :value="1"></el-option>
          <el-option label="第二学期" :value="2"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import dayjs from 'dayjs'
export default {
  props: {
    //获取传入的数据
    value: {
      type: Object
    }
  },
  data() {
    return {
      formData: {
        semester_name: '',
        year: dayjs().format('YYYY'),
        type: ''
      },
      rules:{
        semester_name: [
          { required: true, message: '请输入学期名称', trigger: 'blur' },
        ],
        year: [
          { required: true, message: '请选择学年', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择学期', trigger: 'blur' },
        ]
      }
    };
  },
  mounted() {
    if(this.value.id){
      this.handleLoadForm(this.value);
    }else{
      this.formData = {
        semester_name: '',
        year: dayjs().format('YYYY'),
        type: '',
      }
    }
  },
  methods: {
    // 加载表单数据
    handleLoadForm(id){
      this.$cloud.get('/semester/detail',id).then(res=>{
        this.formData = res
        this.formData.type = Number(this.formData.type)
        this.formData.year = new Date(this.formData.year +1,0,0,0,0,0,0)
      })
    },

    // 提交数据
    handleSubmit(){
      return new Promise((resolve,reject)=>{
        this.$refs['form'].validate((valid)=>{
          if(valid){
            let api = this.formData.id ? 'semester/edit' : 'semester/create'
            let data = {
              id:this.formData.id,
              semesterName:this.formData.semester_name,
              year:dayjs(this.formData.year).format('YYYY'),
              type:this.formData.type,
            }
            this.$cloud.post(api,data).then(()=>{
              resolve(true)
            }).catch((err)=>{
              // this.$message.error(err.response.data.msg)
              reject(false)
            })
          }else{
            reject(false)
          }
        })
      })
    }
  }
};
</script>
