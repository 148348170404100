<!--
 * ======================================
 * 说明： 学期管理
 * 作者： Silence
 * 文件： semester.vue
 * 日期： 2023/8/1 23:03
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <Scrollbar>
      <Table :autoLoading="true" :hasAction="true" :options="handleOptions" :loadData="handleLoadData" title="学期管理" ref="table" :handleInfo="{label: '操作', width: 220}">
        <template #search>
          <el-form :inline="true" :model="formSearch" class="demo-form-inline">
            <el-form-item label="学期" prop="semester_name">
              <el-select class="w-full" v-model="formSearch.type" placeholder="学期" clearable>
                <el-option label="第一学期" :value="1"></el-option>
                <el-option label="第二学期" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学年" prop="year">
              <el-date-picker
                clearable
                style="width: 100%"
                v-model="formSearch.year"
                type="year"
                format="yyyy"
                value-format="yyyy"
                placeholder="选择年">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleRefresh">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #action>
          <el-button type="primary" @click="handleCreate" icon="el-icon-plus">添加学期</el-button>
        </template>
        <template #handle="{index,row}">
          <el-button v-if="row.lock !== 1" type="text" icon="el-icon-edit" @click="handleEdit(row)">修改</el-button>
          <el-button v-if="row.lock !== 1" type="text" icon="el-icon-lock" @click="handleLock(row)">锁定</el-button>
          <el-button v-if="row.lock === 1" type="text" icon="el-icon-unlock" @click="handleUnLock(row)">解锁</el-button>
          <el-button v-if="row.lock !== 1" type="text" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script>
import semesterEdit from './components/semesterEdit'
import dayjs from 'dayjs'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      //搜索表单结构
      formSearch: {
        type: '',
        year: '',
      },
    }
  },
  computed: {},
  created() {
  },
  mounted() {
  },
  methods: {
    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/teaching/semester").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}) {
      //获取分页数据结构
      this.$cloud.page("semester/list", {size, page}, {...this.formSearch}).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleCreate() {
      this.$cloud.dialog({
        title: '添加学期信息',
        data: {},
        component: semesterEdit,
        success: '添加学期信息成功',
        warning: '添加失败',
        refresh: this.handleRefresh
      })
    },
    handleEdit(row) {
      this.$cloud.dialog({
        title: '修改学期信息',
        data: row,
        component: semesterEdit,
        success: '修改学期信息成功',
        warning: '保存失败了',
        refresh: this.handleRefresh
      })
    },


    // 锁定
    handleLock(row){
        this.$confirm('确定要锁定该学期吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$cloud.post("semester/lock", {id: row.id}).then(() => {
            this.$message.success('锁定成功')
            this.handleRefresh()
          })
        })
    },

    //解锁
    handleUnLock(row){
        this.$prompt('请输入登录密码进行解锁', '解锁', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: 'password',
          inputPlaceholder: '请输入密码',
          inputErrorMessage: '输入不能为空',
          inputValidator: (value) => {       // 点击按钮时，对文本框里面的值进行验证
            if ( !value ) {
              return '输入不能为空';
            }
          }
        }).then(({value}) => {
          this.$cloud.post("semester/unlock", {id: row.id,password: value}).then(() => {
            this.$message.success('解锁成功')
            this.handleRefresh()
          })
        })
    },

    handleDelete(row){
        this.$confirm('确定要删除该学期吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$cloud.post("semester/remove", {id: row.id}).then(() => {
            this.$message.success('删除成功！')
            this.handleRefresh()
          })
        })
    },

    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    },
  },
}
</script>
<style lang="scss" scoped></style>
